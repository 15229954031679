<template>
  <div class="middle-navList">
      <div
        class="middle-navList-item"
        @click = "openTc({title:'农田总面积',count:`${ntjbqk.totalArea}亩`})"
      >
          <span  class="navList-item-title">农田总面积</span>
          <div class="navList-item-desc" :style="{backgroundImage:`url(${bgcTitle})`}">
            {{parseInt(ntjbqk?ntjbqk.totalArea:'') }}亩
          </div>
      </div>
      <div
        class="middle-navList-item"
        @click = "openTc({title:'粮食功能区',count:`${ntjbqk.lsgnq}家`})"
      >
          <span  class="navList-item-title">粮食功能区</span>
          <div class="navList-item-desc" :style="{backgroundImage:`url(${bgcTitle})`}">
            {{parseInt(ntjbqk?ntjbqk.grainFieldArea:'') }}亩
          </div>
      </div>
      <div
        class="middle-navList-item"
        @click = "openTc({title:'农业主体',count:`${ntjbqk.agricultureCount}个`})"
      >
          <span  class="navList-item-title">农业主体</span>
          <div class="navList-item-desc" :style="{backgroundImage:`url(${bgcTitle})`}">
            {{parseInt(ntjbqk?ntjbqk.agricultureCount:'') }}个
          </div>
      </div>
      <div
        class="middle-navList-item"
        @click = "openTc({title:'种植补贴',count:`${ntjbqk.fieldTotalSubsidy}元`})"
      >
          <span  class="navList-item-title">种植补贴</span>
          <div class="navList-item-desc" :style="{backgroundImage:`url(${bgcTitle})`}">
            {{parseInt(ntjbqk?ntjbqk.fieldTotalSubsidy:'') }}元
          </div>
      </div>
  </div>
</template>

<script>
export default {
  props:{
    Option:{
      type:Array,
      require:true,
      default:() => {}
    },
    ntjbqk:{
      tyoe:Object,
      default:{
      }
    }
    
  },
  watch:{
    Option:{
      deep:true,
      handler(newValue){
        this.navList=[],
        newValue.forEach(element => {
          this.navList.push(element);
        });
      }
    }
  },
  data(){
    return{
      bgcTitle:require('../../../assets/number-page/topbg.png'),
      navList:[
         {
          name:'农田总面积',
          number:'5622亩',
          bgcTitle:require('../../../assets/number-page/topbg.png')
        },
        {
          name:'粮食功能区',
          number:'15家',
          bgcTitle:require('../../../assets/number-page/topbg.png')
        },
        {
          name:'农业主题',
          number:'6个',
          bgcTitle:require('../../../assets/number-page/topbg.png')
        },
        {
          name:'种植补贴',
          number:'5,421,584元',
          bgcTitle:require('../../../assets/number-page/topbg.png')
        }
      ]
    };
  },
  methods:{
    openTc(type){
      this.$listeners.btn(true,'table',type)
    }
  },
  mounted(){
  },
  watch:{
  }
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
@font-face {
    font-family: Source Han Sans CN;
    src: url(../../../assets/font/np4SotSdlbui.woff);
  }
.middle-navList{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .middle-navList-item{
    width: companyW(180vw);
    height: 100%;
    position: relative;
    .navList-item-title{
     position: absolute;
     top:companyH(-7vh);
     left: 50%;
     transform: translate(-50%);
     height: companyH(16vh);
     font-size: companyH(16vh);
     font-family: Source Han Sans CN;
     font-weight: 500;
     color: #fff;
     line-height: companyH(16vh);
    }
    .navList-item-desc{
      width: 100%;
      text-align: center;
      height: companyH(65vh);
      // background-image: url(../../../assets//drying-page/nav_bgc.png);
      background-repeat: no-repeat;
      background-size: contain;
      font-size: companyW(26vw);
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: companyH(65vh);
    }
  }
}
</style>
